// @flow

import React, { type Node } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styles from './Carousel.module.scss'
import './Carousel.scss'

type Props = {|
  children: Array<Node>,
|}

export default function Carousel({ children }: Props) {
  if (children.length <= 3)
    return (
      <>
        <div className={styles.desktop}>{children}</div>
        <div className={styles.mobile}>
          <Slider {...settings}>{children}</Slider>
        </div>
      </>
    )
  else return <Slider {...settings}>{children}</Slider>
}

const settings = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  rows: 1,
  className: styles.carousel,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplaySpeed: 5000,
        autoplay: true,
        dots: false,
        pauseOnFocus: true,
      },
    },
  ],
}
